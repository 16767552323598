import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Icon Button",
  "type": "Form"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Icon Button`}</strong>{` component represents a clickable icon on the screen, that is associated
with an action taken by the user. Use the `}<strong parentName="p">{`Icon Button`}</strong>{` for small table actions, almost
always with a tooltip.`}</p>
    <p><strong parentName="p">{`Icon Button`}</strong>{` has a `}<a parentName="p" {...{
        "href": "/other/tooltip/"
      }}><strong parentName="a">{`Tooltip`}</strong></a>{` wrapper for the icon, that accepts
`}<inlineCode parentName="p">{`tooltip`}</inlineCode>{` for the explaination of the button and `}<inlineCode parentName="p">{`tooltipVariant`}</inlineCode>{` for the color variant
of the `}<strong parentName="p">{`Tooltip`}</strong>{`.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=false center=true",
        "live": "true",
        "editor": "false",
        "center": "true"
      }}>{`<div style={{
    width: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
}}>
  <IconButton icon={<Icons.BiUser />} tooltip="Edit user"/>
  <Separator />
  <IconButton icon={<Icons.BiUser />} />
</div>
`}</code></pre>
    <h2>{`Props`}</h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Icon Button`}</strong>{` passes all its props to the underlying `}<em parentName="p">{`Component`}</em></p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tooltip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The tooltip style`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tooltipVariant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"light" / "dark" / "mixed"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"light"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the tooltip color variant`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The icon of that icon button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`component`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`React.ElementType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"button"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The underlying root node`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      